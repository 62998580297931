import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {useTokenStore} from "@/store/use-token-store";

export const onCheckLogin = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const $tokenStore = useTokenStore()
    if (!$tokenStore.accessToken) {
        next('/signIn')
        return;
    }

    if (to.path === '/') {
        next('/user/student')
        return;
    }

    next()
}