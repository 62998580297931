import { createApp } from 'vue'
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from '@/App.vue'
import router from "@/router/index";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import '@/assets/css/main.scss'
import VueDaumPostcode from 'vue-daum-postcode'
import { useMessage } from './composables/use-message';
const store = createPinia();
store.use(piniaPluginPersistedstate);
const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.use(store);
app.use(VueDaumPostcode);
app.mount('#app');

// window.addEventListener("unhandledrejection", function (event) {
//   const message = event.reason?.response?.data?.message ?? event.reason?.message;
//   if (message) {
//     useMessage().notify(message, "error");
//   }
// });