import { Admin } from "@/api/admin/model/admin";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useTokenStore = defineStore(
  "token",
  () => {
    const accessToken = ref<string>("");
    const refreshToken = ref<string>("");
    const admin = ref<Admin>();
    return {
      accessToken,
      refreshToken,
      admin,
    };
  },
  {
    persist: true,
  }
);
